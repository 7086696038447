<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="save">
                <b-form-group label="Il mezzo è:">
                    <b-form-radio-group
                        id="isVirtual"
                        v-model="item.isVirtual"
                        name="radio-options"
                    >
                        <b-form-radio value="false" :state="validateState('isVirtual')">Reale</b-form-radio>
                        <b-form-radio value="true" :state="validateState('isVirtual')">Simulatore</b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback
                        id="isVirtual-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>
                <hr>

                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="item.name"
                                  placeholder=""
                                  :state="validateState('name')"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <b-row>
                    <b-col sm="8">
                        <div class="form-group">
                            <b-form-group id="type" label="Tipo" label-for="type" description="">
                                <b-form-select v-model="item.type"
                                               :state="validateState('type')">
                                    <option value="0">- Scegli un tipo -</option>
                                    <option v-for="(type, index) in types" v-bind:value="type" :key="index">
                                        {{ type }}
                                    </option>
                                </b-form-select>
                                <b-form-invalid-feedback
                                    id="type-live-feedback"
                                >Questo campo è obbligatorio.</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group id="capacity" label="Capienza" label-for="capacity" description="">
                            <b-form-input v-model="item.capacity"
                                          placeholder=""></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <div class="form-group">
                    <b-form-group id="drivingLicense" label="Patente" label-for="drivingLicense" description="">
                        <b-form-select v-model="item.drivingLicenseId">
                            <option value="0">- Scegli una Patente -</option>
                            <option v-for="drivingLicense in drivingLicenses" v-bind:value="drivingLicense.id" :key="drivingLicense.id">
                                {{ drivingLicense.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <b-form-group id="licensePlate" label="Targa" label-for="licensePlate" description="">
                    <b-form-input v-model="item.licensePlate"
                                  placeholder=""
                                  :state="validateState('licensePlate')"
                                  @keyup="existsLicensePlate"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questa targa esiste già, è necessario inserire un valore univoco.</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="info" label="Informazioni sul mezzo (opzionale)" label-for="info" description="">
                    <b-form-textarea
                        id="info"
                        v-model="item.info"
                        placeholder=""
                        rows="3"
                        max-rows="6"
                    ></b-form-textarea>
                </b-form-group>


            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="save"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import VehicleDataService from "./VehicleDataService";
import {required} from 'vuelidate/lib/validators'
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";

let existsLicensePlate = false;
const isUniqueLicensePlate = (value) => !existsLicensePlate

export default {
    name: "vehicle-create",
    data() {
        return {
            item: {
                id: null,
                isVirtual: null,
                name: null,
                type: null,
                licensePlate: null,
                info: null,
                drivingLicenseId: null,
                capacity: null
            },
            types: [],
            drivingLicenses: [],
            licensePlates: [],
            submitted: false,
            message: '',
            errorMsg: ''
        };
    },
    validations: {
        item: {
            isVirtual: {
                required
            },
            name: {
                required,
            },
            type: {
                required
            },
            licensePlate: {
                required,
                isUniqueLicensePlate
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                VehicleDataService.create(this.item)
                    .then(response => {
                        this.item.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-vehicle');
                    })
                    .catch(e => {
                        if (e.response.data.errno == 1062) this.errorMsg = 'Il numero di targa esiste già';
                        else this.errorMsg = 'Non hai compilato tutti i campi';

                    });
            }
        },
        existsLicensePlate() {
            existsLicensePlate = this.licensePlates.includes(this.item.licensePlate);
        },
        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        VehicleDataService.getTypes()
            .then(response => {
                this.types = response.data;
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });
        VehicleDataService.getAll()
            .then(response => {
                if (response.data) {
                    for (const vehicle of response.data) {
                        if (vehicle.licensePlate !== null) this.licensePlates.push(vehicle.licensePlate);
                    }
                }
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
