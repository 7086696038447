<template>
    <div>
        <div v-if="currentVehicle" class="card">
            <b-form @submit="updateVehicle">
                <br>


                <b-form-group label="Il mezzo è:">
                    <b-form-radio-group
                        id="isVirtual"
                        v-model="currentVehicle.isVirtual"
                        name="radio-options"
                        :state="validateState('isVirtual')"
                        aria-describedby="name-live-feedback">

                        <b-form-radio value="false">Reale</b-form-radio>
                        <b-form-radio value="true">Un simulatore</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <hr>

                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="currentVehicle.name"
                                  placeholder=""
                                  :state="validateState('name')"
                                  aria-describedby="name-live-feedback">></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <b-row>
                    <b-col sm="8">
                        <div class="form-group">
                            <b-form-group id="type" label="Tipo" label-for="type" description="">
                                <b-form-select v-model="currentVehicle.type">
                                    <option value="0">- Scegli un tipo -</option>
                                    <option v-for="(type, index) in types" v-bind:value="type" :key="index">
                                        {{ type }}
                                    </option>
                                </b-form-select>
                                <b-form-invalid-feedback
                                    id="type-live-feedback"
                                >Questo campo è obbligatorio.</b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group id="capacity" label="Capienza" label-for="capacity" description="">
                            <b-form-input v-model="currentVehicle.capacity"
                                          placeholder=""></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <div class="form-group">
                    <b-form-group id="drivingLicense" label="Patente" label-for="drivingLicense" description="">
                        <b-form-select v-model="currentVehicle.drivingLicenseId">
                            <option value="0">- Scegli una Patente -</option>
                            <option v-for="drivingLicense in drivingLicenses" v-bind:value="drivingLicense.id" :key="drivingLicense.id">
                                {{ drivingLicense.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <b-form-group id="licensePlate" label="Targa" label-for="licensePlate" description="">
                    <b-form-input v-model="currentVehicle.licensePlate"
                                  placeholder=""
                                  :state="validateState('licensePlate')"
                                  @change="existsLicensePlate"
                                  @keyup="existsLicensePlate"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questa targa esiste già, è necessario inserire un valore univoco.</b-form-invalid-feedback>
                </b-form-group>

                <p>Indisponibilità per manutenzione o altro (opzionale)</p>
                <div class="row">
                    <div class="col-md-5">
                        <b-form-group id="unavailabilityDateStart" label="Dal" label-for="unavailabilityDateStart" description="">
                            <b-form-datepicker v-model="currentVehicle.unavailabilityDateStart" placeholder=""></b-form-datepicker>
                        </b-form-group>
                    </div>
                    <div class="col-md-5">
                        <b-form-group id="unavailabilityDateEnd" label="Al" label-for="unavailabilityDateEnd" description="">
                            <b-form-datepicker v-model="currentVehicle.unavailabilityDateEnd" placeholder=""></b-form-datepicker>
                        </b-form-group>
                    </div>

                    <div class="col-md-2 text-center">
                        <b-form-group label="Cancella" v-if="currentVehicle.unavailabilityDateStart || currentVehicle.unavailabilityDateEnd">
                            <b-button @click="resetUnavailability()" variant="warning"><b-icon icon="x-circle"/> </b-button>
                        </b-form-group>
                    </div>
                </div>


                <b-form-group id="info" label="Informazioni sul mezzo (opzionale)" label-for="info" description="">
                    <b-form-textarea
                        id="info"
                        v-model="currentVehicle.info"
                        placeholder=""
                        rows="3"
                        max-rows="6"
                    ></b-form-textarea>
                </b-form-group>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateVehicle" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="deleteVehicle" :disabled="this.$store.state.loading"> <b-icon icon="trash"/></b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a Vehicle...' }}</p>
        </div>
    </div>
</template>

<script>
import VehicleDataService from "./VehicleDataService";
import {required} from 'vuelidate/lib/validators'
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";

let existsLicensePlate = false;
const isUniqueLicensePlate = (value) => !existsLicensePlate

export default {
    name: "vehicle-edit",
    data() {
        return {
            currentVehicle: null,
            types: [],
            drivingLicenses: [],
            licensePlates: [],
            submitted: false,
            message: '',
            errorMsg: '',
        };
    },
    props: ["vehicleId"],
    validations: {
        currentVehicle: {
            isVirtual: {
                required
            },
            name: {
                required,
            },
            type: {
                required
            },
            licensePlate: {
                required,
                isUniqueLicensePlate
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.currentVehicle[name];
            return $dirty ? !$error : null;
        },
        getVehicle(id) {
            VehicleDataService.get(id)
                .then(response => {
                    this.currentVehicle = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateVehicle() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                VehicleDataService.update(this.currentVehicle.id, this.currentVehicle)
                    .then(response => {
                        this.submitted = true;
                        this.message = 'The class was updated successfully!';
                        this.$emit('updated-vehicle', this.currentVehicle);
                    })
                    .catch(e => {
                        if (e.response.data.errno == 1062) this.errorMsg = 'Il numero di targa esiste già';
                        else this.errorMsg = 'Non hai compilato tutti i campi';

                    });
            }
        },

        deleteVehicle() {
            if(confirm(`Vuoi cancellare il mezzo ${this.currentVehicle.name}?`)) {
                VehicleDataService.delete(this.currentVehicle.id)
                    .then(response => {
                        this.$emit('deleted-vehicle');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },
        resetUnavailability() {
            this.currentVehicle.unavailabilityDateStart = null;
            this.currentVehicle.unavailabilityDateEnd = null;
        },
        existsLicensePlate() {
            existsLicensePlate = this.licensePlates.includes(this.currentVehicle.licensePlate);
        },
        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getVehicle(this.vehicleId);
        VehicleDataService.getTypes()
            .then(response => {
                this.types = response.data;
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });
        VehicleDataService.getAll()
            .then(response => {
                if (response.data) {
                    for (const vehicle of response.data) {
                        if (vehicle.licensePlate !== null && vehicle.id !== this.vehicleId) this.licensePlates.push(vehicle.licensePlate);
                    }
                }
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
