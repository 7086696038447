<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Mezzi</h3>
            </div>

            <div class="col-md-6">
                <search-bar @search-name="searchName($event)"/>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentVehicle = null">
                <b-icon icon="plus"/> Aggiungi Mezzo
            </button>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col" class="opt">Tipo</th>
                            <th scope="col" class="opt">Targa</th>
                            <th scope="col" class="opt">Patente</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': item.id == currentId }"
                            v-for="item in vehicles"
                            :key="item.id"
                            @click="setActiveVehicle(item)"
                        >
                            <td>{{ item.name }} {{ (item.isVirtual) ? '(sim)' : '' }}</td>
                            <td class="opt">{{ item.type }}</td>
                            <td class="opt">{{ item.licensePlate }}</td>
                            <td class="opt">{{ (item.drivingLicense) ? item.drivingLicense.name : '' }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.vehicles.length == 0">Ancora nessun mezzo inserito</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <vehicle-edit :vehicle-id="currentVehicle.id" :key="currentVehicle.id"
                                  v-on:updated-vehicle="refreshCurrentVehicle($event)"
                                  v-on:deleted-vehicle="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <vehicle-create
                        v-on:created-vehicle="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import VehicleDataService from "./VehicleDataService";
import VehicleEdit from "./VehicleEdit";
import VehicleCreate from "./VehicleCreate";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "vehicles-index",
    components: {VehicleEdit, VehicleCreate, SearchBar},
    data() {
        return {
            vehicles: [],
            currentVehicle: null,
            currentId: -1,
        };
    },
    methods: {
        retrieveVehicles() {
            VehicleDataService.getAll()
                .then(response => {
                    this.vehicles = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveVehicles();
            this.currentVehicle = null;
            this.currentId = -1;
        },

        refreshCurrentVehicle(itemVehicle) {
            this.vehicles.forEach(function (item) {
                if (item.id == itemVehicle.id) {
                    item.name = itemVehicle.name;
                    item.type = itemVehicle.type;
                    item.drivingLicenseId = itemVehicle.drivingLicenseId;
                    item.licensePlate = itemVehicle.licensePlate;
                    item.isVirtual = itemVehicle.isVirtual;
                    console.log(item);
                }
            });
            this.closeEditor();
            //this.currentVehicle = itemVehicle;
            //this.currentId = itemVehicle.id;
        },

        setActiveVehicle(item) {
            this.currentVehicle = item;
            this.currentId = item.id;
        },

        removeAllVehicles() {
            VehicleDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentVehicle = null;
            this.currentId = -1;
            VehicleDataService.findByName(name)
                .then(response => {
                    this.vehicles = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.currentVehicle = null;
            this.currentId = -1;
        }
    },
    mounted() {
        this.retrieveVehicles();
    }
};
</script>


<style>
</style>
